import React, { Component } from 'react';
import '../App.css';
import '../script.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Modal from './ModalUser';

class Headeruser extends Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      sms_updates:'',
      lang:'en'
    };
  }


  componentDidMount(){
    var lan = localStorage.getItem("lang");
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const token = localStorage.getItem("token");
    const u = jwtDecode(token);
    axios.get('/api/users/get-user/'+u.id).then(res => {
      this.setState({
        sms_updates: res.data.sms_updates
       });
       
    })
    .catch(err =>{
      console.log('Error from professional');
    })
  }
  
  

  render() {
    var lang=this.state.lang;
    return (
      <div className="col-md-2 dash_menu">
        {(function(){
    if (this.state.sms_updates=='na') {
        return <><Modal/></>
    } 
    
}).call(this)}
        <div className="left_menu">
        <ul><li><Link className="lang" to='/user-dashboard'>{lang=='fr'?(<>tableau de bord</>):(<>Dashboard</>)} </Link></li>
            <li><a className="lang" href='/create-request'>{lang=='fr'?(<>Créer une demande</>):(<>Create Request</>)}</a></li>
            <li><Link to='/pending-requests' className="lang">{lang=='fr'?(<>Requêtes en attente</>):(<>Pending Requests</>)}</Link></li>
            <li><Link to='/request-applications-list' className="lang">{lang=='fr'?(<>Applications</>):(<>Applications</>)}</Link></li>
            <li ><Link className="lang" to='/user-active-requests'>{lang=='fr'?(<>Demandes actives</>):(<>Active Requests</>)}</Link></li>
            <li><Link className="lang" to='/user-completed-requests'>{lang=='fr'?(<>demandes complétées</>):(<>Completed Requests</>)}</Link></li>
            <li><Link to="/user-order-history" className="lang">{lang=='fr'?(<>Historique des commandes</>):(<>Order History</>)}</Link></li>
            <li><Link to="/user-account-setting" className="lang">{lang=='fr'?(<>mon profil</>):(<>My Profile</>)}</Link></li>
            <li><Link to='/user-logout' className="lang">{lang=='fr'?(<>Se déconnecter</>):(<>Logout</>)}</Link></li>
        </ul>
        </div>
        
        </div>
        
        
    );
  }
}

export default Headeruser;